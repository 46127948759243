<template>
  <div class="estoque-body">
    <GenericTable
      ref="listaInventario"
      name="material"
      title="Inventário de materiais"
      :disabledTitle="true"
      :cols="[
        'Tipo de Material',
        'Descrição',
        'Quantidade',
      ]"
      :cols-name="[
        'tipo_material',
        'descricao',
        'Quantidade',
      ]"
      :cols-map="
        (i) => [
          i.tipoMaterial,
          i.descricao,
          i.quantidade,
        ]
      "
      :route="materialRoute"
      :permissionsToWrite="['rw_material']"
      :filters="filtersLista"
      idKey="id_item"
      hasDetalhesInventario
      @showDetalhesInventario="showDetalhesInventario"
      hasPagination
      :noadd="true"
    >
    <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Descrição</label>
              <b-form-input
                v-model="filtersLista.descricao"
                autocomplete="off"
                class="invision-input sm"
                ref="filter_descricao"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Tipo de Material</label>
              <GenericSelect
                name="tipoMaterial"
                labelKey="nome"
                v-model="filtersLista.id_tipo_material"
                route="tipoMaterial"
                ref="filter_tipo_material"
              ></GenericSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericSelect from '@/components/Form/GenericSelect';

export default {
  components: {
    GenericSelect,
  },

  props: {
    filtersLista: {
      type: Object,
    },
    tipoItemSelect: {
      type: Object,
    },
  },

  data() {
    return {
      materialRoute: 'item/controleInventario',
      filters: {
        descricao: '',
        id_tipo_material: null,
      },
      noAdd: false,
    };
  },

  methods: {
    showDetalhesInventario(item) {
      this.$emit('showDetalhesInventario', item);
    },
  },

};
</script>
