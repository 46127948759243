var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"estoque-body"},[_c('GenericTable',{ref:"listaInventario",attrs:{"name":"material","title":"Inventário de materiais","rastreabilidadeRoute":"materialRastrear","disabledTitle":true,"cols":[
      'ID',
      'Descrição',
      'Qtd de peças',
      'Tipo',
      'Unidade',
      'Médico',
      'Setor',
      'Fornecedor' ],"cols-name":[
      'id',
      'descricao',
      'quantidade',
      'tipo_material',
      'Unidade',
      'medico',
      'Setor',
      'fornecedor' ],"cols-map":function (i) { return [
        i.shownId,
        i.descricao,
        i.quantidade,
        i.tipo_material,
        i.unidade,
        i.medico,
        i.setor,
        i.fornecedor ]; },"route":_vm.materialRoute,"permissionsToWrite":['rw_material'],"filters":_vm.filtersLista,"idKey":"id_material","hasPagination":"","noadd":true},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v("QR Code")]),_c('b-form-input',{ref:"filter_code",staticClass:"invision-input sm",attrs:{"autocomplete":"off"},model:{value:(_vm.filtersLista.code),callback:function ($$v) {_vm.$set(_vm.filtersLista, "code", $$v)},expression:"filtersLista.code"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v("ID")]),_c('b-form-input',{ref:"filter_id",staticClass:"invision-input sm",attrs:{"autocomplete":"off"},model:{value:(_vm.filtersLista.ids),callback:function ($$v) {_vm.$set(_vm.filtersLista, "ids", $$v)},expression:"filtersLista.ids"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Unidade")]),_c('b-form-select',{ref:"filters_id_unidade",attrs:{"name":"unidade","labelKey":"nome","options":_vm.optionsUnidade,"disabled":!_vm.hasBlocoOrCmeOrOpme},model:{value:(_vm.filtersLista.id_unidade),callback:function ($$v) {_vm.$set(_vm.filtersLista, "id_unidade", $$v)},expression:"filtersLista.id_unidade"}},[_c('template',{slot:"first"},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(" -- Por favor, selecione uma opção -- ")])],1)],2)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Setor")]),_c('GenericSelect',{ref:"filter_id_setor",attrs:{"name":"Setor","labelKey":"nome","route":"Setor"},model:{value:(_vm.filtersLista.id_setor),callback:function ($$v) {_vm.$set(_vm.filtersLista, "id_setor", $$v)},expression:"filtersLista.id_setor"}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }