var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"estoque-body"},[_c('GenericTable',{ref:"listaInventario",attrs:{"name":"material","title":"Inventário de materiais","disabledTitle":true,"cols":[
      'Tipo de Material',
      'Descrição',
      'Quantidade' ],"cols-name":[
      'tipo_material',
      'descricao',
      'Quantidade' ],"cols-map":function (i) { return [
        i.tipoMaterial,
        i.descricao,
        i.quantidade ]; },"route":_vm.materialRoute,"permissionsToWrite":['rw_material'],"filters":_vm.filtersLista,"idKey":"id_item","hasDetalhesInventario":"","hasPagination":"","noadd":true},on:{"showDetalhesInventario":_vm.showDetalhesInventario},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Descrição")]),_c('b-form-input',{ref:"filter_descricao",staticClass:"invision-input sm",attrs:{"autocomplete":"off"},model:{value:(_vm.filtersLista.descricao),callback:function ($$v) {_vm.$set(_vm.filtersLista, "descricao", $$v)},expression:"filtersLista.descricao"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Tipo de Material")]),_c('GenericSelect',{ref:"filter_tipo_material",attrs:{"name":"tipoMaterial","labelKey":"nome","route":"tipoMaterial"},model:{value:(_vm.filtersLista.id_tipo_material),callback:function ($$v) {_vm.$set(_vm.filtersLista, "id_tipo_material", $$v)},expression:"filtersLista.id_tipo_material"}})],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }