<template>
  <div>
    <div class="header">
      <PageTitle title="Controle de Inventário" />
      <div class="nav-general">
        <b-nav-item
          @click="goToRoute('InventarioMaterial', null)"
          :class="nav.route === 'InventarioMaterial' ? 'active' : 'disabled'"
        >
          Inventário
        </b-nav-item>
        <b-nav-item
          @click="goToRoute('ListMateriais')"
          :class="nav.route === 'ListMateriais' ? 'active' : 'disabled'"
        >
          Lista de materiais
        </b-nav-item>
      </div>
    </div>
    <cardless-base>
      <div class="nav-container">
        <transition name="fade" mode="out-in">
          <component
            v-bind:is="component[nav.route]"
            :filtersLista="filtersLista"
            :tipoItemSelect="tipoItemSelect"
            @showDetalhesInventario="showDetalhesInventario"
            @goToListaMaterialAndFilter="goToListaMaterialAndFilter"
            @update-filters-lista="updateFiltersLista"
            @update-tipo-item-select="updateTipoItemSelect"
          />
        </transition>
      </div>
    </cardless-base>
  </div>
</template>

<script>
import CardlessBase from '@/templates/CardlessBase';
import PageTitle from '@/templates/PageTitle';
import ListMateriais from './Components/ListMateriais';
import InventarioMaterial from './Components/InventarioMaterial';
import genericRequest from '@/services/genericRequest';

export default {
  components: {
    CardlessBase,
    PageTitle,
    ListMateriais,
    InventarioMaterial,
  },

  data() {
    return {
      nav: {
        route: 'InventarioMaterial',
        route: 'ListMateriais',
      },
      component: {
        ListMateriais: 'ListMateriais',
        InventarioMaterial: 'InventarioMaterial',
      },
      filtersLista: {
        inEstoque: true,
      },
      tipoItemSelect: null,
    };
  },

  mounted() {
    this.goToRoute('InventarioMaterial', null)
    genericRequest.getAll({}, `item/controleInventario`);
    if (this.$route.query.routeTipo) this.goToRoute(this.$route.query.routeTipo, null);
  },

  methods: {
    editRouteIndex() {
      return true;
    },

    showDetalhesInventario(item) {
      this.goToRoute('ListMateriais', item);
    },

    goToRoute(route, prefilters) {
      if (route === 'listMateriais') this.filtersLista = { inEstoque: true };
      if (prefilters) { // Se existe um préfiltro, ele é aplicado
        this.filtersLista = {
          inEstoque: true,
          descricao: prefilters.detalhesInventarioHref.params.descricao,
          id_tipo_material: prefilters.detalhesInventarioHref.params.id_tipo_material 
        };
      } else { // se não existe, os filtros são limpos e "zerados"
        this.filtersLista = { descricao: '', id_tipo_material: null };
      }
      this.tipoItemSelect = null;
      this.nav.route = route;
    },

    updateTipoItemSelect(tipoItem) {
      this.tipoItemSelect = tipoItem;
    },

    async goToListaMaterialAndFilter(v) {
      this.updateFiltersLista('id_tipo_item', v.tipoItem.id_tipo_item);
      this.updateTipoItemSelect(v.tipoItem);
      this.nav.route = 'listMaterial';
    },

    updateFiltersLista(prop, value) {
      this.filtersLista = {
        ...this.filtersLista,
        [prop]: value,
      };
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #fff;
  margin-top: -2rem;
  padding: 2rem 4rem;
  border-bottom: 1px solid #CFD1E6;
  margin-bottom: 3rem;
}
.nav-general {
  list-style: none;
  display: flex;
  position: absolute;
  top: 12rem;
  height: 2.875rem;
}
.active {
  border-bottom: 6px solid #209F85;
}
.active a{
  color:#209F85;
}
b-nav-item {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.75px;
  text-align: center;
}
.disabled a{
  color: #7E829F;
}
.nav-container {
  transition: margin-left 0.3s ease-in-out;
}
</style>
